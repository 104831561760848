import React from "react";

import { EmailAuthProvider, User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";
import { firebaseConfig } from "./util";

const locales = {
  "en-US": "English (United States)",
  "es-ES": "Spanish (Spain)",
  "de-DE": "German",
};

type Venue = {
  name: string;
  published: boolean;
  related_events: EntityReference[];
  main_image: string;
  venue_image: string;
  venue_icon: string;
  venue_heading: string;
  venue_subheading: string;
};

type Event = {
  language: string;
  related_venue: EntityReference;
  published: boolean;
};

type EventAuth = {
  broadcast_code: string;
  related_event: EntityReference;
};

const venueSchema = buildSchema<Venue>({
  name: "Venue",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    published: ({ values }) =>
      buildProperty({
        title: "Published",
        dataType: "boolean",
        columnWidth: 100,
      }),
    related_events: {
      dataType: "array",
      title: "Related events",
      description: "Events associated with this venue.",
      of: {
        dataType: "reference",
        path: "events",
      },
    },
    main_image: buildProperty({
      // The `buildProperty` method is an utility function used for type checking
      title: "Logo Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
    venue_image: buildProperty({
      // The `buildProperty` method is an utility function used for type checking
      title: "Venue Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
    venue_icon: buildProperty({
      // The `buildProperty` method is an utility function used for type checking
      title: "Venue Icon",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
    venue_heading: {
      title: "Header",
      validation: { required: true },
      dataType: "string",
    },
    venue_subheading: {
      title: "Sub Header",
      validation: { required: true },
      dataType: "string",
    },
  },
});

const eventAuthSchema = buildSchema<EventAuth>({
  name: "Event Code",
  properties: {
    broadcast_code: {
      title: "Broadcast Code",
      validation: { required: true },
      dataType: "string",
    },
    related_event: {
      dataType: "reference",
      validation: { required: true },
      path: "events",
      title: "Related Event",
      description: "To which Event does this Authorization code belong to?",
    },
  },
});

const eventSchema = buildSchema<Event>({
  name: "Event",
  properties: {
    language: {
      title: "Language",
      validation: { required: true },
      dataType: "string",
    },

    published: ({ values }) =>
      buildProperty({
        title: "Published",
        dataType: "boolean",
        columnWidth: 100,
      }),
    related_venue: {
      dataType: "reference",
      validation: { required: true },
      path: "venues",
      title: "Related Venue",
      description: "To which Venue does this event belong to?",
    },
  },
});

const localeSchema = buildSchema({
  customId: locales,
  name: "Locale",
  properties: {
    title: {
      title: "Title",
      validation: { required: true },
      dataType: "string",
    },
    selectable: {
      title: "Selectable",
      description: "Is this locale selectable",
      dataType: "boolean",
    },
    video: {
      title: "Video",
      dataType: "string",
      validation: { required: false },
      config: {
        storageMeta: {
          mediaType: "video",
          storagePath: "videos",
          acceptedFiles: ["video/*"],
          storeUrl: true,
        },
      },
    },
  },
});

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        buildCollection({
          path: "venues",
          schema: venueSchema,
          name: "Venues",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
          subcollections: [
            buildCollection({
              name: "Locales",
              path: "locales",
              schema: localeSchema,
            }),
          ],
        }),
        buildCollection({
          path: "events",
          schema: eventSchema,
          name: "Events",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
          subcollections: [
            buildCollection({
              name: "Locales",
              path: "locales",
              schema: localeSchema,
            }),
          ],
        }),

        buildCollection({
          path: "eventAuth",
          schema: eventAuthSchema,
          name: "Event Auth",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
          subcollections: [
            buildCollection({
              name: "Locales",
              path: "locales",
              schema: localeSchema,
            }),
          ],
        }),
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
  }) => {
    console.log("Allowing access to", user);
    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({
      roles: ["admin"],
    });
    authController.setExtra(sampleUserData);
    return true;
  };

  return (
    <FirebaseCMSApp
      name={"Active Voice"}
      signInOptions={[
        {
          provider: EmailAuthProvider.PROVIDER_ID,
        },
      ]}
      authentication={myAuthenticator}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
    />
  );
}
