export const firebaseConfig = {
  apiKey: "AIzaSyCuXfuESPnM3D4Qykrxpbomr85QHuebXgc",
  authDomain: "activevoice-f0e43.firebaseapp.com",
  databaseURL: "https://activevoice-f0e43-default-rtdb.firebaseio.com",
  projectId: "activevoice-f0e43",
  storageBucket: "activevoice-f0e43.appspot.com",
  messagingSenderId: "732874949749",
  appId: "1:732874949749:web:ecf9128551b3005f1ee43c",
  measurementId: "G-66X69G0MNH"
};
